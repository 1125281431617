export const gray00 = "var(--seed-scale-color-gray-00)";
export const gray50 = "var(--seed-scale-color-gray-50)";
export const gray100 = "var(--seed-scale-color-gray-100)";
export const gray200 = "var(--seed-scale-color-gray-200)";
export const gray300 = "var(--seed-scale-color-gray-300)";
export const gray400 = "var(--seed-scale-color-gray-400)";
export const gray500 = "var(--seed-scale-color-gray-500)";
export const gray600 = "var(--seed-scale-color-gray-600)";
export const gray700 = "var(--seed-scale-color-gray-700)";
export const gray800 = "var(--seed-scale-color-gray-800)";
export const gray900 = "var(--seed-scale-color-gray-900)";
export const carrot50 = "var(--seed-scale-color-carrot-50)";
export const carrot100 = "var(--seed-scale-color-carrot-100)";
export const carrot200 = "var(--seed-scale-color-carrot-200)";
export const carrot300 = "var(--seed-scale-color-carrot-300)";
export const carrot400 = "var(--seed-scale-color-carrot-400)";
export const carrot500 = "var(--seed-scale-color-carrot-500)";
export const carrot600 = "var(--seed-scale-color-carrot-600)";
export const carrot700 = "var(--seed-scale-color-carrot-700)";
export const carrot800 = "var(--seed-scale-color-carrot-800)";
export const carrot900 = "var(--seed-scale-color-carrot-900)";
export const carrot950 = "var(--seed-scale-color-carrot-950)";
export const blue50 = "var(--seed-scale-color-blue-50)";
export const blue100 = "var(--seed-scale-color-blue-100)";
export const blue200 = "var(--seed-scale-color-blue-200)";
export const blue300 = "var(--seed-scale-color-blue-300)";
export const blue400 = "var(--seed-scale-color-blue-400)";
export const blue500 = "var(--seed-scale-color-blue-500)";
export const blue600 = "var(--seed-scale-color-blue-600)";
export const blue700 = "var(--seed-scale-color-blue-700)";
export const blue800 = "var(--seed-scale-color-blue-800)";
export const blue900 = "var(--seed-scale-color-blue-900)";
export const blue950 = "var(--seed-scale-color-blue-950)";
export const red50 = "var(--seed-scale-color-red-50)";
export const red100 = "var(--seed-scale-color-red-100)";
export const red200 = "var(--seed-scale-color-red-200)";
export const red300 = "var(--seed-scale-color-red-300)";
export const red400 = "var(--seed-scale-color-red-400)";
export const red500 = "var(--seed-scale-color-red-500)";
export const red600 = "var(--seed-scale-color-red-600)";
export const red700 = "var(--seed-scale-color-red-700)";
export const red800 = "var(--seed-scale-color-red-800)";
export const red900 = "var(--seed-scale-color-red-900)";
export const red950 = "var(--seed-scale-color-red-950)";
export const green50 = "var(--seed-scale-color-green-50)";
export const green100 = "var(--seed-scale-color-green-100)";
export const green200 = "var(--seed-scale-color-green-200)";
export const green300 = "var(--seed-scale-color-green-300)";
export const green400 = "var(--seed-scale-color-green-400)";
export const green500 = "var(--seed-scale-color-green-500)";
export const green600 = "var(--seed-scale-color-green-600)";
export const green700 = "var(--seed-scale-color-green-700)";
export const green800 = "var(--seed-scale-color-green-800)";
export const green900 = "var(--seed-scale-color-green-900)";
export const green950 = "var(--seed-scale-color-green-950)";
export const yellow50 = "var(--seed-scale-color-yellow-50)";
export const yellow100 = "var(--seed-scale-color-yellow-100)";
export const yellow200 = "var(--seed-scale-color-yellow-200)";
export const yellow300 = "var(--seed-scale-color-yellow-300)";
export const yellow400 = "var(--seed-scale-color-yellow-400)";
export const yellow500 = "var(--seed-scale-color-yellow-500)";
export const yellow600 = "var(--seed-scale-color-yellow-600)";
export const yellow700 = "var(--seed-scale-color-yellow-700)";
export const yellow800 = "var(--seed-scale-color-yellow-800)";
export const yellow900 = "var(--seed-scale-color-yellow-900)";
export const yellow950 = "var(--seed-scale-color-yellow-950)";
export const pink50 = "var(--seed-scale-color-pink-50)";
export const pink100 = "var(--seed-scale-color-pink-100)";
export const pink200 = "var(--seed-scale-color-pink-200)";
export const pink300 = "var(--seed-scale-color-pink-300)";
export const pink400 = "var(--seed-scale-color-pink-400)";
export const pink500 = "var(--seed-scale-color-pink-500)";
export const pink600 = "var(--seed-scale-color-pink-600)";
export const pink700 = "var(--seed-scale-color-pink-700)";
export const pink800 = "var(--seed-scale-color-pink-800)";
export const pink900 = "var(--seed-scale-color-pink-900)";
export const pink950 = "var(--seed-scale-color-pink-950)";
export const purple50 = "var(--seed-scale-color-purple-50)";
export const purple100 = "var(--seed-scale-color-purple-100)";
export const purple200 = "var(--seed-scale-color-purple-200)";
export const purple300 = "var(--seed-scale-color-purple-300)";
export const purple400 = "var(--seed-scale-color-purple-400)";
export const purple500 = "var(--seed-scale-color-purple-500)";
export const purple600 = "var(--seed-scale-color-purple-600)";
export const purple700 = "var(--seed-scale-color-purple-700)";
export const purple800 = "var(--seed-scale-color-purple-800)";
export const purple900 = "var(--seed-scale-color-purple-900)";
export const purple950 = "var(--seed-scale-color-purple-950)";
export const grayAlpha50 = "var(--seed-scale-color-gray-alpha-50)";
export const grayAlpha100 = "var(--seed-scale-color-gray-alpha-100)";
export const grayAlpha200 = "var(--seed-scale-color-gray-alpha-200)";
export const grayAlpha500 = "var(--seed-scale-color-gray-alpha-500)";
export const carrotAlpha50 = "var(--seed-scale-color-carrot-alpha-50)";
export const carrotAlpha100 = "var(--seed-scale-color-carrot-alpha-100)";
export const carrotAlpha200 = "var(--seed-scale-color-carrot-alpha-200)";
export const blueAlpha50 = "var(--seed-scale-color-blue-alpha-50)";
export const blueAlpha100 = "var(--seed-scale-color-blue-alpha-100)";
export const blueAlpha200 = "var(--seed-scale-color-blue-alpha-200)";
export const redAlpha50 = "var(--seed-scale-color-red-alpha-50)";
export const redAlpha100 = "var(--seed-scale-color-red-alpha-100)";
export const redAlpha200 = "var(--seed-scale-color-red-alpha-200)";
export const yellowAlpha50 = "var(--seed-scale-color-yellow-alpha-50)";
export const yellowAlpha100 = "var(--seed-scale-color-yellow-alpha-100)";
export const yellowAlpha200 = "var(--seed-scale-color-yellow-alpha-200)";
export const greenAlpha50 = "var(--seed-scale-color-green-alpha-50)";
export const greenAlpha100 = "var(--seed-scale-color-green-alpha-100)";
export const greenAlpha200 = "var(--seed-scale-color-green-alpha-200)";
