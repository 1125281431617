export const fontSize10 = "var(--seed-scale-dimension-font-size-10)";
export const fontSize25 = "var(--seed-scale-dimension-font-size-25)";
export const fontSize50 = "var(--seed-scale-dimension-font-size-50)";
export const fontSize75 = "var(--seed-scale-dimension-font-size-75)";
export const fontSize100 = "var(--seed-scale-dimension-font-size-100)";
export const fontSize150 = "var(--seed-scale-dimension-font-size-150)";
export const fontSize200 = "var(--seed-scale-dimension-font-size-200)";
export const fontSize300 = "var(--seed-scale-dimension-font-size-300)";
export const fontSize400 = "var(--seed-scale-dimension-font-size-400)";
export const fontSize500 = "var(--seed-scale-dimension-font-size-500)";
export const fontSize600 = "var(--seed-scale-dimension-font-size-600)";
export const fontSize700 = "var(--seed-scale-dimension-font-size-700)";
export const fontSize800 = "var(--seed-scale-dimension-font-size-800)";
export const fontSize900 = "var(--seed-scale-dimension-font-size-900)";
export const fontSize1000 = "var(--seed-scale-dimension-font-size-1000)";
export const fontSize1100 = "var(--seed-scale-dimension-font-size-1100)";
export const fontSize1200 = "var(--seed-scale-dimension-font-size-1200)";
export const fontSize1300 = "var(--seed-scale-dimension-font-size-1300)";
