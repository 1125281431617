export const h1 = "seed-semantic-typography-h1";
export const h2 = "seed-semantic-typography-h2";
export const h3 = "seed-semantic-typography-h3";
export const h4 = "seed-semantic-typography-h4";

export const title1Bold = "seed-semantic-typography-title1-bold";
export const title1Regular = "seed-semantic-typography-title1-regular";

export const title2Bold = "seed-semantic-typography-title2-bold";
export const title2Regular = "seed-semantic-typography-title2-regular";

export const title3Bold = "seed-semantic-typography-title3-bold";
export const title3Regular = "seed-semantic-typography-title3-regular";

export const subtitle1Bold = "seed-semantic-typography-subtitle1-bold";
export const subtitle1Regular = "seed-semantic-typography-subtitle1-regular";

export const subtitle2Bold = "seed-semantic-typography-subtitle2-bold";
export const subtitle2Regular = "seed-semantic-typography-subtitle2-regular";

export const bodyL1Bold = "seed-semantic-typography-body-l1-bold";
export const bodyL1Regular = "seed-semantic-typography-body-l1-regular";

export const bodyL2Bold = "seed-semantic-typography-body-l2-bold";
export const bodyL2Regular = "seed-semantic-typography-body-l2-regular";

export const bodyM1Bold = "seed-semantic-typography-body-m1-bold";
export const bodyM1Regular = "seed-semantic-typography-body-m1-regular";

export const bodyM2Bold = "seed-semantic-typography-body-m2-bold";
export const bodyM2Regular = "seed-semantic-typography-body-m2-regular";

export const caption1Bold = "seed-semantic-typography-caption1-bold";
export const caption1Regular = "seed-semantic-typography-caption1-regular";

export const caption2Bold = "seed-semantic-typography-caption2-bold";
export const caption2Regular = "seed-semantic-typography-caption2-regular";

export const label1Bold = "seed-semantic-typography-label1-bold";
export const label1Regular = "seed-semantic-typography-label1-regular";

export const label2Bold = "seed-semantic-typography-label2-bold";
export const label2Regular = "seed-semantic-typography-label2-regular";

export const label3Bold = "seed-semantic-typography-label3-bold";
export const label3Regular = "seed-semantic-typography-label3-regular";

export const label4Bold = "seed-semantic-typography-label4-bold";
export const label4Regular = "seed-semantic-typography-label4-regular";

export const label5Bold = "seed-semantic-typography-label5-bold";
export const label5Regular = "seed-semantic-typography-label5-regular";

export const label6Bold = "seed-semantic-typography-label6-bold";
export const label6Regular = "seed-semantic-typography-label6-regular";
